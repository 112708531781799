<template>
  <div class="welcome-video">
    <iframe class="wistia_embed"
            name="wistia_embed"
            :src="wistiaUrl"
            title="Wistia video player"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            allowfullscreen
            mozallowfullscreen
            webkitallowfullscreen
            oallowfullscreen
            msallowfullscreen>
    </iframe>
    <br>
    <b-button class="btn btn-h3-blue btn-h3-small"
              type="button"
              v-if="displayButton"
              @click="$emit('close')">
      End Video
    </b-button>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name   : 'WelcomeVideo',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        wistiaUrl : 'https://fast.wistia.net/embed/iframe/' +
          '1e5hlaihyx?autoPlay=true',
        displayButton : false,
      }
    },
    mounted() {
      const that = this;
      setTimeout(() => that.displayButton = true, 3000);
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/welcome-video";
</style>
